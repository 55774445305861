

/*
  Media Queries
*/



@media screen and (min-width: 576px) {
  .main--content {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mobile--nav,
  .mobile--footer {
    display: none;
  }
  .modal-settings .modal-dialog {
    max-width: 700px;
  }
}
@media screen and (min-width: 992px) {
  .main--content {
    height: 100vh;
    overflow-y: auto;
  }
  .main--canvas {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .main--canvas {
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media screen and (min-width: 1440px) {
  .dashboard--table thead tr th:first-child {
    padding-left: 38px;
  }
  .dashboard--table thead tr th:last-child {
    padding-right: 38px;
  }
  .dashboard--table tbody tr td:first-child {
    padding-left: 30px;
    min-width: 310px;
    max-width: 310px;
  }
  .dashboard--table tbody tr td:last-child {
    padding-right: 20px;
  }
}
@media screen and (max-width: 991px) {
  .form--page .container {
    max-width: 100%;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    margin-left: -280px;
    box-shadow: 0 5px 20px rgba(0,102,204,.1);
  }
  .sidebar--show {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .form__logo-canvas .back__btn {
    left: 20px;
  }
}
@media screen and (max-width: 575px) {
  .form--page {
    padding: 10px 0px 0px 0px;
    align-items: initial;
  }
  .form--page .form--canvas {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .form__logo-canvas {
    margin-top: auto;
    margin-bottom: auto;
  }
  .form__logo-canvas .form__logo {
    margin-bottom: 10px;
  }
  .form--block {
    padding: 36px 24px 24px 24px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .forgotPass__msg {
    font-size: 14px;
  }
  .form__heading {
    font-size: 24px;
    margin-bottom: 36px;
  }
  .account--page {
    padding: 0;
  }
  .account--page .container--sm {
    display: flex;
  }
  .account--page .form--block {
    width: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .white--box {
    padding: 20px;
  }
  .sidebar,
  .sidebar--show {
    display: none;
  }
  .main--app {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 375px) {
  .mobileNav--menu-f .mobileNav__icon {
    font-size: 14px;
  }
  .mobileNav--menu-f .mobileNav__text {
    font-size: 12px;
  }
}



/*
  Media Queries
*/

